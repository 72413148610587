import * as React from "react";
import styled, { StyledComponentProps } from "styled-components/macro";

import { GrDrag as GrabberIcon } from "react-icons/gr";
import {
  RiDeleteBin5Fill as DeleteIcon,
  RiEdit2Fill as EditIcon,
  RiAddFill as AddIcon,
  RiInformationFill as DetailsIcon,
} from "react-icons/ri";

type ButtonProps = StyledComponentProps<
  "button",
  any,
  { type: "button" },
  "type"
>;

export const DeleteButton: React.FC<ButtonProps> = (props) => (
  <DeleteButtonStyle {...props}>
    <DeleteIcon />
  </DeleteButtonStyle>
);

export const EditButton: React.FC<ButtonProps> = (props) => (
  <EditButtonStyle {...props}>
    <EditIcon />
  </EditButtonStyle>
);

export const DetailsButton: React.FC<ButtonProps> = (props) => (
  <EditButtonStyle {...props}>
    <DetailsIcon />
  </EditButtonStyle>
);

export const AddButton: React.FC<ButtonProps> = (props) => (
  <AddButtonStyle {...props}>
    <AddIcon />
  </AddButtonStyle>
);

export const GrabberButton: React.FC<ButtonProps> = (props) => (
  <GrabberButtonStyle {...props}>
    <GrabberIcon />
  </GrabberButtonStyle>
);

export const IconButton = styled.button.attrs({ type: "button" })`
  display: grid;
  place-content: center;
  padding: 0.6rem;
  border-radius: 3px;
  color: #19193b;
  background-color: hsl(240 40% 16% / 0.1);
  border: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: currentColor;
  }
`;

const DeleteButtonStyle = styled(IconButton)`
  color: #f71033;
  background-color: hsl(351 94% 52% / 0.1);
`;

const EditButtonStyle = styled(IconButton)`
  color: #4057e3;
  background-color: hsl(232 74% 57% / 0.1);
`;

const AddButtonStyle = styled(IconButton)`
  color: #8fa0bf;
  background-color: hsl(219 27% 65% / 0.2);
`;

const GrabberButtonStyle = styled(IconButton)`
  padding: 0.4rem;
  cursor: grab;
  svg {
    width: 1rem;
    height: 1rem;
    path {
      stroke: currentColor;
    }
  }
`;
