import * as React from "react";
import { StyleSheetManager } from "styled-components/macro";

import { NotionWrapper } from "web-app/notions";

import { NotionPreviewGlobalStyle } from "./style";
import { MDXWidgetPreview } from "../widgets/mdx/preview";
import { PreviewBanner } from "../components/preview-banner";
import { useSyncedNotionPreview } from "../hooks/use-synced-notion-preview";
import { useDefaultUserSettings } from "../hooks/use-default-user-settings";

const MemoizedMDXWidgetPreview = React.memo(MDXWidgetPreview);

type NotionPreviewProps = {
  value: string;
} & Record<string, any>;

export const NotionPreview: React.FC<NotionPreviewProps> = (props) => {
  console.log("🐧 ~ file: NotionPreview ~ line 24 ~ props", { props });
  const { value, entry = {} } = props;
  const notionId = entry?.getIn(["data", "id"]) as string;
  const { addPreview } = useSyncedNotionPreview();

  const [iframe] =
    document.querySelectorAll<HTMLIFrameElement>("#preview-pane");
  const iframeHeadElem = iframe?.contentDocument?.head;

  // Handle opening the preview page
  const handleOnPreviewClick = React.useCallback(() => {
    if (notionId) {
      // Add notion value to local storage
      addPreview(notionId, value);
      window?.open(`/preview/${notionId}`, "_blank");
    }
  }, [addPreview, notionId, value]);

  // Sync notion preview value with local storage
  React.useEffect(() => {
    if (notionId) {
      // Add notion value to local storage
      addPreview(notionId, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, notionId]);

  // Default user settings
  useDefaultUserSettings();

  return (
    <StyleSheetManager target={iframeHeadElem}>
      <NotionPreviewGlobalStyle />
      <PreviewBanner onClick={handleOnPreviewClick} />
      <NotionWrapper>
        <MemoizedMDXWidgetPreview value={value.trim()} />
      </NotionWrapper>
    </StyleSheetManager>
  );
};
