import { createGlobalStyle } from "styled-components/macro";

export const NotionPreviewGlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,500;1,700&family=Noto+Sans+KR:wght@400;500;700;900&display=swap');
 * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-weight: inherit;
  font-family: inherit;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-appearance: none;
  -moz-appearance: none;
}
html {
  display: flex;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  background-color: white;
  color: #19193b;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Noto Sans KR", sans-serif;
}
body {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: inherit;
}

#preview-pane {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: currentColor;
}

`;
