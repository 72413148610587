import * as React from "react";
import styled from "styled-components/macro";

interface PreviewBannerProps {
  onClick: () => void;
}

export const PreviewBanner: React.FC<PreviewBannerProps> = (props) => {
  const { onClick } = props;

  return (
    <PreviewBannerStyle>
      <p>Do you want to see how this page will look inside a Lesson page?</p>
      <PreviewBannerButton type="button" onClick={onClick}>
        Open
      </PreviewBannerButton>
    </PreviewBannerStyle>
  );
};

const PreviewBannerStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem;
  margin: 1rem;
  border-radius: 24px;
  background-color: #fcf1cf;
  p {
    font-size: 1rem;
    letter-spacing: 0.022rem;
    line-height: 1.5;
    text-align: left;
    font-weight: 500;
    color: #19193b;
    margin-right: 1rem;
  }
`;

const PreviewBannerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  padding: 0.65rem 2rem;
  border-radius: 3rem;
  border: 1.5px solid #f2b90f;
  background: #f2b90f;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
`;
