import * as React from "react";
import styled from "styled-components/macro";

import { RiArrowRightCircleLine } from "react-icons/ri";

export const Control: React.FC = () => (
  <ContentUploadLinkStyle>
    <a
      href={`${import.meta.env.REACT_APP_STUDIO_URL}/studio/content-upload`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <RiArrowRightCircleLine />
      Access Content upload page
    </a>
  </ContentUploadLinkStyle>
);

export const Preview: React.FC = () => {
  return null;
};

const ContentUploadLinkStyle = styled.div`
  text-align: left;
  width: 100%;
  margin-top: -1.2rem;
  position: relative;
  z-index: 1;
  background: white;
  color: hsl(220deg 56% 50%);
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: underline;
    color: hsl(220deg 56% 50%);
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
    margin-right: 0.3rem;
  }
`;
